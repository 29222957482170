exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-alumni-index-jsx": () => import("./../../../src/pages/alumni/index.jsx" /* webpackChunkName: "component---src-pages-alumni-index-jsx" */),
  "component---src-pages-blog-block-quote-jsx": () => import("./../../../src/pages/blog/block-quote.jsx" /* webpackChunkName: "component---src-pages-blog-block-quote-jsx" */),
  "component---src-pages-blog-bullet-points-alpha-jsx": () => import("./../../../src/pages/blog/bullet-points-alpha.jsx" /* webpackChunkName: "component---src-pages-blog-bullet-points-alpha-jsx" */),
  "component---src-pages-blog-bullet-points-circle-jsx": () => import("./../../../src/pages/blog/bullet-points-circle.jsx" /* webpackChunkName: "component---src-pages-blog-bullet-points-circle-jsx" */),
  "component---src-pages-blog-bullet-points-jsx": () => import("./../../../src/pages/blog/bullet-points.jsx" /* webpackChunkName: "component---src-pages-blog-bullet-points-jsx" */),
  "component---src-pages-blog-bullet-points-num-jsx": () => import("./../../../src/pages/blog/bullet-points-num.jsx" /* webpackChunkName: "component---src-pages-blog-bullet-points-num-jsx" */),
  "component---src-pages-blog-custom-hyperlinks-section-jsx": () => import("./../../../src/pages/blog/custom-hyperlinks-section.jsx" /* webpackChunkName: "component---src-pages-blog-custom-hyperlinks-section-jsx" */),
  "component---src-pages-blog-custom-image-jsx": () => import("./../../../src/pages/blog/custom-image.jsx" /* webpackChunkName: "component---src-pages-blog-custom-image-jsx" */),
  "component---src-pages-blog-custom-section-jsx": () => import("./../../../src/pages/blog/custom-section.jsx" /* webpackChunkName: "component---src-pages-blog-custom-section-jsx" */),
  "component---src-pages-blog-custom-sub-section-jsx": () => import("./../../../src/pages/blog/custom-sub-section.jsx" /* webpackChunkName: "component---src-pages-blog-custom-sub-section-jsx" */),
  "component---src-pages-blog-custom-youtube-section-jsx": () => import("./../../../src/pages/blog/custom-youtube-section.jsx" /* webpackChunkName: "component---src-pages-blog-custom-youtube-section-jsx" */),
  "component---src-pages-blog-footer-data-jsx": () => import("./../../../src/pages/blog/FooterData.jsx" /* webpackChunkName: "component---src-pages-blog-footer-data-jsx" */),
  "component---src-pages-blog-h-2-jsx": () => import("./../../../src/pages/blog/h2.jsx" /* webpackChunkName: "component---src-pages-blog-h-2-jsx" */),
  "component---src-pages-blog-h-3-jsx": () => import("./../../../src/pages/blog/h3.jsx" /* webpackChunkName: "component---src-pages-blog-h-3-jsx" */),
  "component---src-pages-blog-h-5-jsx": () => import("./../../../src/pages/blog/h5.jsx" /* webpackChunkName: "component---src-pages-blog-h-5-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-styling-text-jsx": () => import("./../../../src/pages/blog/styling-text.jsx" /* webpackChunkName: "component---src-pages-blog-styling-text-jsx" */),
  "component---src-pages-blog-table-column-jsx": () => import("./../../../src/pages/blog/table-column.jsx" /* webpackChunkName: "component---src-pages-blog-table-column-jsx" */),
  "component---src-pages-blog-table-header-jsx": () => import("./../../../src/pages/blog/table-header.jsx" /* webpackChunkName: "component---src-pages-blog-table-header-jsx" */),
  "component---src-pages-blog-table-row-jsx": () => import("./../../../src/pages/blog/table-row.jsx" /* webpackChunkName: "component---src-pages-blog-table-row-jsx" */),
  "component---src-pages-blog-table-styling-jsx": () => import("./../../../src/pages/blog/table-styling.jsx" /* webpackChunkName: "component---src-pages-blog-table-styling-jsx" */),
  "component---src-pages-brand-building-index-jsx": () => import("./../../../src/pages/brand-building/index.jsx" /* webpackChunkName: "component---src-pages-brand-building-index-jsx" */),
  "component---src-pages-bschool-series-summer-placement-prep-index-jsx": () => import("./../../../src/pages/bschool-series/summer-placement-prep/index.jsx" /* webpackChunkName: "component---src-pages-bschool-series-summer-placement-prep-index-jsx" */),
  "component---src-pages-career-support-index-jsx": () => import("./../../../src/pages/career-support/index.jsx" /* webpackChunkName: "component---src-pages-career-support-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-content-writing-course-index-jsx": () => import("./../../../src/pages/content-writing-course/index.jsx" /* webpackChunkName: "component---src-pages-content-writing-course-index-jsx" */),
  "component---src-pages-digital-brand-building-index-jsx": () => import("./../../../src/pages/digital-brand-building/index.jsx" /* webpackChunkName: "component---src-pages-digital-brand-building-index-jsx" */),
  "component---src-pages-hire-from-us-index-jsx": () => import("./../../../src/pages/hire-from-us/index.jsx" /* webpackChunkName: "component---src-pages-hire-from-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industrycreds-experience-index-jsx": () => import("./../../../src/pages/industrycreds-experience/index.jsx" /* webpackChunkName: "component---src-pages-industrycreds-experience-index-jsx" */),
  "component---src-pages-kraftshala-careers-[id]-js": () => import("./../../../src/pages/kraftshala-careers/[id].js" /* webpackChunkName: "component---src-pages-kraftshala-careers-[id]-js" */),
  "component---src-pages-kraftshala-careers-index-jsx": () => import("./../../../src/pages/kraftshala-careers/index.jsx" /* webpackChunkName: "component---src-pages-kraftshala-careers-index-jsx" */),
  "component---src-pages-kraftshala-hiring-old-index-jsx": () => import("./../../../src/pages/kraftshala-hiring-old/index.jsx" /* webpackChunkName: "component---src-pages-kraftshala-hiring-old-index-jsx" */),
  "component---src-pages-kraftshala-live-index-jsx": () => import("./../../../src/pages/kraftshala-live/index.jsx" /* webpackChunkName: "component---src-pages-kraftshala-live-index-jsx" */),
  "component---src-pages-kraftshala-live-join-index-jsx": () => import("./../../../src/pages/kraftshala-live/join/index.jsx" /* webpackChunkName: "component---src-pages-kraftshala-live-join-index-jsx" */),
  "component---src-pages-kraftshala-live-register-index-js": () => import("./../../../src/pages/kraftshala-live/register/index.js" /* webpackChunkName: "component---src-pages-kraftshala-live-register-index-js" */),
  "component---src-pages-kraftshala-marketing-courses-index-jsx": () => import("./../../../src/pages/kraftshala-marketing-courses/index.jsx" /* webpackChunkName: "component---src-pages-kraftshala-marketing-courses-index-jsx" */),
  "component---src-pages-landing-page-index-jsx": () => import("./../../../src/pages/landing-page/index.jsx" /* webpackChunkName: "component---src-pages-landing-page-index-jsx" */),
  "component---src-pages-marketing-glossary-block-quote-jsx": () => import("./../../../src/pages/marketing-glossary/block-quote.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-block-quote-jsx" */),
  "component---src-pages-marketing-glossary-bullet-points-alpha-jsx": () => import("./../../../src/pages/marketing-glossary/bullet-points-alpha.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-bullet-points-alpha-jsx" */),
  "component---src-pages-marketing-glossary-bullet-points-circle-jsx": () => import("./../../../src/pages/marketing-glossary/bullet-points-circle.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-bullet-points-circle-jsx" */),
  "component---src-pages-marketing-glossary-bullet-points-jsx": () => import("./../../../src/pages/marketing-glossary/bullet-points.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-bullet-points-jsx" */),
  "component---src-pages-marketing-glossary-bullet-points-num-jsx": () => import("./../../../src/pages/marketing-glossary/bullet-points-num.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-bullet-points-num-jsx" */),
  "component---src-pages-marketing-glossary-custom-hyperlinks-section-jsx": () => import("./../../../src/pages/marketing-glossary/custom-hyperlinks-section.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-custom-hyperlinks-section-jsx" */),
  "component---src-pages-marketing-glossary-custom-image-jsx": () => import("./../../../src/pages/marketing-glossary/custom-image.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-custom-image-jsx" */),
  "component---src-pages-marketing-glossary-custom-section-jsx": () => import("./../../../src/pages/marketing-glossary/custom-section.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-custom-section-jsx" */),
  "component---src-pages-marketing-glossary-custom-sub-section-jsx": () => import("./../../../src/pages/marketing-glossary/custom-sub-section.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-custom-sub-section-jsx" */),
  "component---src-pages-marketing-glossary-custom-youtube-section-jsx": () => import("./../../../src/pages/marketing-glossary/custom-youtube-section.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-custom-youtube-section-jsx" */),
  "component---src-pages-marketing-glossary-glossaries-list-jsx": () => import("./../../../src/pages/marketing-glossary/GlossariesList.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-glossaries-list-jsx" */),
  "component---src-pages-marketing-glossary-h-2-jsx": () => import("./../../../src/pages/marketing-glossary/h2.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-h-2-jsx" */),
  "component---src-pages-marketing-glossary-h-3-jsx": () => import("./../../../src/pages/marketing-glossary/h3.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-h-3-jsx" */),
  "component---src-pages-marketing-glossary-h-5-jsx": () => import("./../../../src/pages/marketing-glossary/h5.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-h-5-jsx" */),
  "component---src-pages-marketing-glossary-index-jsx": () => import("./../../../src/pages/marketing-glossary/index.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-index-jsx" */),
  "component---src-pages-marketing-glossary-mdx-frontmatter-g-path-jsx": () => import("./../../../src/pages/marketing-glossary/{mdx.frontmatter__gPath}.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-mdx-frontmatter-g-path-jsx" */),
  "component---src-pages-marketing-glossary-styling-text-jsx": () => import("./../../../src/pages/marketing-glossary/styling-text.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-styling-text-jsx" */),
  "component---src-pages-marketing-glossary-table-column-jsx": () => import("./../../../src/pages/marketing-glossary/table-column.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-table-column-jsx" */),
  "component---src-pages-marketing-glossary-table-header-jsx": () => import("./../../../src/pages/marketing-glossary/table-header.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-table-header-jsx" */),
  "component---src-pages-marketing-glossary-table-row-jsx": () => import("./../../../src/pages/marketing-glossary/table-row.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-table-row-jsx" */),
  "component---src-pages-marketing-glossary-table-styling-jsx": () => import("./../../../src/pages/marketing-glossary/table-styling.jsx" /* webpackChunkName: "component---src-pages-marketing-glossary-table-styling-jsx" */),
  "component---src-pages-marketing-launchpad-digital-marketing-course-in-hindi-index-jsx": () => import("./../../../src/pages/marketing-launchpad/digital-marketing-course-in-hindi/index.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-digital-marketing-course-in-hindi-index-jsx" */),
  "component---src-pages-marketing-launchpad-digital-marketing-course-in-hindi-thank-you-jsx": () => import("./../../../src/pages/marketing-launchpad/digital-marketing-course-in-hindi/thank-you.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-digital-marketing-course-in-hindi-thank-you-jsx" */),
  "component---src-pages-marketing-launchpad-digital-marketing-course-index-jsx": () => import("./../../../src/pages/marketing-launchpad/digital-marketing-course/index.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-digital-marketing-course-index-jsx" */),
  "component---src-pages-marketing-launchpad-digital-marketing-course-thank-you-jsx": () => import("./../../../src/pages/marketing-launchpad/digital-marketing-course/thank-you.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-digital-marketing-course-thank-you-jsx" */),
  "component---src-pages-marketing-launchpad-ecommerce-marketing-course-index-jsx": () => import("./../../../src/pages/marketing-launchpad/ecommerce-marketing-course/index.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-ecommerce-marketing-course-index-jsx" */),
  "component---src-pages-marketing-launchpad-ecommerce-marketing-course-thank-you-jsx": () => import("./../../../src/pages/marketing-launchpad/ecommerce-marketing-course/thank-you.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-ecommerce-marketing-course-thank-you-jsx" */),
  "component---src-pages-marketing-launchpad-part-time-content-writing-and-social-media-marketing-course-index-jsx": () => import("./../../../src/pages/marketing-launchpad/part-time-content-writing-and-social-media-marketing-course/index.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-part-time-content-writing-and-social-media-marketing-course-index-jsx" */),
  "component---src-pages-marketing-launchpad-part-time-content-writing-and-social-media-marketing-course-thank-you-jsx": () => import("./../../../src/pages/marketing-launchpad/part-time-content-writing-and-social-media-marketing-course/thank-you.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-part-time-content-writing-and-social-media-marketing-course-thank-you-jsx" */),
  "component---src-pages-marketing-launchpad-part-time-digital-marketing-course-index-jsx": () => import("./../../../src/pages/marketing-launchpad/part-time-digital-marketing-course/index.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-part-time-digital-marketing-course-index-jsx" */),
  "component---src-pages-marketing-launchpad-part-time-digital-marketing-course-thank-you-jsx": () => import("./../../../src/pages/marketing-launchpad/part-time-digital-marketing-course/thank-you.jsx" /* webpackChunkName: "component---src-pages-marketing-launchpad-part-time-digital-marketing-course-thank-you-jsx" */),
  "component---src-pages-press-releases-index-jsx": () => import("./../../../src/pages/press-releases/index.jsx" /* webpackChunkName: "component---src-pages-press-releases-index-jsx" */),
  "component---src-pages-professional-brand-management-in-the-digital-age-application-form-index-jsx": () => import("./../../../src/pages/professional-brand-management-in-the-digital-age-application-form/index.jsx" /* webpackChunkName: "component---src-pages-professional-brand-management-in-the-digital-age-application-form-index-jsx" */),
  "component---src-pages-professional-brand-management-in-the-digital-age-index-jsx": () => import("./../../../src/pages/professional-brand-management-in-the-digital-age/index.jsx" /* webpackChunkName: "component---src-pages-professional-brand-management-in-the-digital-age-index-jsx" */),
  "component---src-pages-programs-index-jsx": () => import("./../../../src/pages/programs/index.jsx" /* webpackChunkName: "component---src-pages-programs-index-jsx" */),
  "component---src-pages-sales-course-index-jsx": () => import("./../../../src/pages/sales-course/index.jsx" /* webpackChunkName: "component---src-pages-sales-course-index-jsx" */),
  "component---src-pages-sales-course-thank-you-jsx": () => import("./../../../src/pages/sales-course/thank-you.jsx" /* webpackChunkName: "component---src-pages-sales-course-thank-you-jsx" */),
  "component---src-pages-sales-leadership-index-jsx": () => import("./../../../src/pages/sales-leadership/index.jsx" /* webpackChunkName: "component---src-pages-sales-leadership-index-jsx" */),
  "component---src-pages-screening-test-index-jsx": () => import("./../../../src/pages/screening-test/index.jsx" /* webpackChunkName: "component---src-pages-screening-test-index-jsx" */),
  "component---src-pages-screening-test-welcome-jsx": () => import("./../../../src/pages/screening-test/Welcome.jsx" /* webpackChunkName: "component---src-pages-screening-test-welcome-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-unsubscribe-index-jsx": () => import("./../../../src/pages/unsubscribe/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-jsx" */),
  "component---src-pages-work-with-us-apply-now-[id]-js": () => import("./../../../src/pages/work-with-us-apply-now/[id].js" /* webpackChunkName: "component---src-pages-work-with-us-apply-now-[id]-js" */),
  "component---src-pages-work-with-us-apply-now-index-jsx": () => import("./../../../src/pages/work-with-us-apply-now/index.jsx" /* webpackChunkName: "component---src-pages-work-with-us-apply-now-index-jsx" */),
  "component---src-pages-wp-blog-blog-post-jsx": () => import("./../../../src/pages/wp-blog/blog-post.jsx" /* webpackChunkName: "component---src-pages-wp-blog-blog-post-jsx" */)
}

